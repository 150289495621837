import request from '@/utils/request'


// 查询电池数据列表
export function listBatteryReading(query) {
  return request({
    url: '/batteryReading/batteryReading/list',
    method: 'get',
    params: query
  })
}

// 查询电池数据分页
export function pageBatteryReading(query) {
  return request({
    url: '/batteryReading/batteryReading/page',
    method: 'get',
    params: query
  })
}

// 查询电池数据详细
export function getBatteryReading(data) {
  return request({
    url: '/batteryReading/batteryReading/detail',
    method: 'get',
    params: data
  })
}

// 新增电池数据
export function addBatteryReading(data) {
  return request({
    url: '/batteryReading/batteryReading/add',
    method: 'post',
    data: data
  })
}

// 修改电池数据
export function updateBatteryReading(data) {
  return request({
    url: '/batteryReading/batteryReading/edit',
    method: 'post',
    data: data
  })
}

// 删除电池数据
export function delBatteryReading(data) {
  return request({
    url: '/batteryReading/batteryReading/delete',
    method: 'post',
    data: data
  })
}
